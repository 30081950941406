import * as React from 'react';

import FormControlWrapper from 'kiini/FormControlWrapper';
import ArgumentValueSelect from '../widgets/ArgumentValueSelect';

import {VAR_ROUTE_PART_ID} from "../constEventHandlers"



const SelectRoutePartWidget = ({ instance, store, path, label }) => {

    const routePartIdNames = store.getRoutePartIdNames();
    const selectOptions = routePartIdNames.map(entry => ({ value: entry.id, label: entry.name }));


    return <FormControlWrapper label={label} showError={false}>
        <ArgumentValueSelect
            instance={instance}
            fieldName="values"
            argumentName={VAR_ROUTE_PART_ID}
            selectOptions={selectOptions} />
    </FormControlWrapper>

}

export default SelectRoutePartWidget;