export const WALKINGTRAILTYPE = 'content_objects.roles.WalkingTrail';
export const CYCLINGTRAILTYPE = 'content_objects.roles.CyclingTrail';
export const BOATINGTRAILTYPE = 'content_objects.roles.BoatingTrail';
export const CARTRAILTYPE = 'content_objects.roles.CarTrail';
export const HORSETRAILTYPE = 'content_objects.roles.HorseTrail';
export const EDITABLE_EXPERIENCE_TYPE = 'content_objects.model.EditableExperience';

export const IMAGE_CLAZZ = "content_objects.model.Image";
export const VIDEO_CLAZZ = "content_objects.model.Video";
export const AUDIO_CLAZZ = "content_objects.model.Audio";


export const ORGANISATION_ROLE_EXPERIENCE_SITE_SHARE_ROLE = "uso.db.organisation_roles.ExperienceSiteShareRole"

export const OBJECT_TYPE_TRACK = 'OBJECT_TYPE_NEW_TRACK';


export const OBJECT_TYPE_IMAGE = 'OBJECT_TYPE_IMAGE';
export const OBJECT_TYPE_VIDEO = 'OBJECT_TYPE_VIDEO';
export const OBJECT_TYPE_AUDIO = 'OBJECT_TYPE_AUDIO';
export const OBJECT_TYPE_SCENE = 'OBJECT_TYPE_SCENE';
export const OBJECT_TYPE_STORYBLOCK = 'OBJECT_TYPE_STORYBLOCK';
export const OBJECT_TYPE_SPOT = 'OBJECT_TYPE_SPOT';
export const OBJECT_TYPE_RUNNABLE_OBJECT = 'OBJECT_TYPE_RUNNABLE_OBJECT';
export const OBJECT_TYPE_INSTRUCTION = 'OBJECT_TYPE_INSTRUCTION';
export const OBJECT_TYPE_START_SPOT = 'OBJECT_TYPE_START_SPOT';

export const OBJECT_TYPE_PARKING_SPOT = 'uso.db.roles.ParkingPlace';
export const OBJECT_TYPE_BUS_STOP = 'uso.db.roles.BusStop';
export const OBJECT_TYPE_RAILWAY_STATION = 'uso.db.roles.RailwayStation';


export const CONTENT_OBJECTS_LIST = 'CONTENT_OBJECTS_LIST';
export const ROUTE_PART_lIST = 'ROUTE_PART_lIST';
export const INSTRUCTION_LIST = 'INSTRUCTION_LIST';
export const START_SPOTS_LIST = 'START_SPOTS_LIST';
export const POINTS_LIST = 'POINTS_LIST';


export const ROUTE_GENERAL_TYPE = 'ROUTE_GENERAL_TYPE';
export const ROUTE_AUDIENCE_TYPE = 'ROUTE_AUDIENCE_TYPE';
export const ROUTE_PROPERTIES_TYPE =  'ROUTE_PROPERTIES_TYPE';
export const ROUTE_THEMES_TYPE = 'ROUTE_THEMES_TYPE';
export const ROUTE_LENGHT_DURATIONS_TYPE = 'ROUTE_LENGHT_DURATIONS_TYPE';
export const EDITABLE_EXPERIENCE_MAP_TYPE = 'EDITABLE_EXPERIENCE_MAP_TYPE';
export const ENGINE_EVENT_HANDLING_TYPE = 'ENGINE_EVENT_HANDLING_TYPE';




export const IMAGE_ASSET_TYPE = 'image'
export const AUDIO_ASSET_TYPE = 'audio'
export const VIDEO_ASSET_TYPE = 'video'

export const CLAZZ_FILTER_NAME = 'clazz'