import * as React from 'react';

import FormControlWrapper from 'kiini/FormControlWrapper';
import ArgumentValueSelect from '../widgets/ArgumentValueSelect';

import {VAR_CONTENT_OBJECT_LINK_ID} from "./../constEventHandlers"



const SelectGameStorySpotWidget = ({ instance, store, path, label }) => {

    const contentObjectLinksWithName = store.getContentObjectLinksWithName();
    const selectOptions = contentObjectLinksWithName.map(entry => ({ value: entry.id, label: entry.name }));


    return <FormControlWrapper label={label} showError={false}>
        <ArgumentValueSelect
            instance={instance}
            fieldName="values"
            argumentName={VAR_CONTENT_OBJECT_LINK_ID}
            selectOptions={selectOptions} />
    </FormControlWrapper>

}

export default SelectGameStorySpotWidget;