import * as React from 'react';

import { VAR_CONTENT_OBJECT_LINK_ID } from '../constEventHandlers';

import FormControlWrapper from 'kiini/FormControlWrapper';
import ArgumentValueSelect from '../widgets/ArgumentValueSelect';

const GamePointConditionWidget = ({ id, instance, label, eventName, store }) => {

    const contentObjectLinksWithName = store.getContentObjectLinksWithName();
    const selectOptions = contentObjectLinksWithName.map(entry => ({ value: entry.id, label: entry.name }));


    return <FormControlWrapper label={label} showError={false}>
        <ArgumentValueSelect
            instance={instance}
            fieldName="values"
            argumentName={VAR_CONTENT_OBJECT_LINK_ID}
            selectOptions={selectOptions} />
    </FormControlWrapper>


    

}

export default GamePointConditionWidget;