import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton'
//import InboxIcon from '@material-ui/icons/MoveToInbox';
//import DraftsIcon from '@material-ui/icons/Drafts';
// import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// import StarBorder from '@material-ui/icons/StarBorder';
// import DeleteIcon from '@material-ui/icons/DeleteOutline';
//import RemoveIcon from '@material-ui/icons/RemoveCircle'

import {BiBookOpen} from 'react-icons/bi'
import {BiCommentDots} from 'react-icons/bi'
import {BiFlag} from 'react-icons/bi';
import {BiGitBranch} from 'react-icons/bi'
import {BiCodeBlock} from 'react-icons/bi'
import {BiXCircle} from 'react-icons/bi'

import { observer } from 'mobx-react'

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { 
    CONTENT_OBJECTS_LIST, 
    ROUTE_PART_lIST, 
    INSTRUCTION_LIST, 
    ROUTE_GENERAL_TYPE, 
    ROUTE_AUDIENCE_TYPE,
    ROUTE_THEMES_TYPE,
    EDITABLE_EXPERIENCE_MAP_TYPE,
    ROUTE_LENGHT_DURATIONS_TYPE,
    ROUTE_PROPERTIES_TYPE, 
    POINTS_LIST,
    ENGINE_EVENT_HANDLING_TYPE
} from 'model/const';

import {getRoleWithClazz} from 'model/content-object-utils';

const useStyles = makeStyles(theme => ({
    mainItemText: {
        marginLeft: 8,
        zIndex: 1200
    },
    nested: {
    //    marginLeft: 40,
        paddingLeft: theme.spacing(4),
        zIndex: 1200
    },
    listItemIcon: {
        color: theme.palette.menu.default,
        minWidth: 16
    },
    removeButton: {
        color: theme.palette.menu.default,
    }
}));

const DragHandle = sortableHandle(() => <span style={{ marginLeft: 12 }}>::::</span>);

const SortableItem = sortableElement(({ item, classes, onRemove, onItemClick }) => {

    return <ListItem button className={classes.nested}>
        <ListItemText onClick={() => onItemClick(item.id)} primary={<Typography variant="caption">{item.name}</Typography>}/>
        <IconButton className={classes.removeButton} edge="end" aria-label="delete" onClick={() => onRemove(item.id)} >
            <BiXCircle size={14}/>
        </IconButton>
        <DragHandle />
    </ListItem>
});

const SortableList = sortableContainer(({ children }) => {
    return <List component="div" disablePadding>{children}</List>;
});


const ObjectList = (props) => {
    return <SortableList useDragHandle lockAxis="y" onSortEnd={props.onSortEnd}>
        {props.items.map((item, i) => (
            <SortableItem
                key={i}
                item={item}
                index={i}
                classes={props.classes}
                onRemove={props.onRemove}
                onItemClick={props.onItemClick}
            />
        ))}
    </SortableList>
}


const ObjectsListSelector = observer(({experienceEditorStore, name, listName, initialOpen, namedItems, icon}) => {

    const [open, setOpen] = React.useState(initialOpen);
    const classes = useStyles();

    const handleOpen = () => {
        setOpen(!open);
    };

    const onItemClick = (id) => {
        console.log("On select ", listName, " id ", id)
        experienceEditorStore.selectElement({ elementSet: listName, id });
    }

    const onRemoveItem = (id) => {
        experienceEditorStore.removeFromList(listName, id)
    }

    const onSort = ({ oldIndex, newIndex }) => {
        experienceEditorStore.moveInList(listName, oldIndex, newIndex);
    }

    return <React.Fragment>
        <ListItem button onClick={handleOpen}>
            {open ? <ExpandLess /> : <ExpandMore />}
            <ListItemIcon className={classes.listItemIcon}>
                {icon}
            </ListItemIcon>
            <ListItemText primary={<Typography variant="caption">{name}</Typography>} />
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <ObjectList
                onSortEnd={onSort}
                items={namedItems}
                classes={classes}
                onItemClick={onItemClick}
                onRemove={onRemoveItem} />
        </Collapse>
    </React.Fragment>
});

const ObjectSelector = observer(({ experienceEditorStore }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    const experience = experienceEditorStore.experience;
   
    const tracks = experience.route && experience.route.routeParts ?
        experience.route.routeParts.map((routePart, indx) => {
            const trackNumber = indx + 1;
            const name = routePart.name ? routePart.name : `Track ${trackNumber}`;
            return { id: routePart.id, name }
        }) : [];

    const contentObjectItems = experience.contentObjectLinks.map(contentObjectLink => {
        const name = experienceEditorStore.getContentNameForContentObjectLink(contentObjectLink);
        return { id: contentObjectLink.id, name};
    });
    
    const pointItems = experience.points.map(point => {
        return {id: point.id, name: point.description};
    });

    const instructionItems = experience.experienceInstructions.instructions.map(instruction => {
        return { id: instruction.id, name: instruction.title };
    })

    const showEditPanel = (editObjectContext) => {
        experienceEditorStore.selectElement(editObjectContext);
    }

    

    const engineEventHandling = getRoleWithClazz(experience.contentRoles, "content_objects.roles.EngineEventHandling");

    
    return (
        
        <List
            component="div"
            aria-labelledby="nested-list-subheader"
            >
            <ListItem button onClick={handleClick}>
                {open ? <ExpandLess /> : <ExpandMore />}
                <ListItemIcon className={classes.listItemIcon}>
                    <BiCodeBlock />
                </ListItemIcon>
                <ListItemText primary="Storytrail" />

            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button className={classes.nested} onClick={() => showEditPanel({ elementSet: ROUTE_GENERAL_TYPE, id: experience.id })}>
                        <ListItemText primary="Basic" />
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={() => showEditPanel({ elementSet: ROUTE_LENGHT_DURATIONS_TYPE, id: experience.id })}>
                        <ListItemText primary="Length Duration" />
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={() => showEditPanel({ elementSet: ROUTE_THEMES_TYPE, id: experience.id })}>
                        <ListItemText primary="Theme" />
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={() => showEditPanel({ elementSet: ROUTE_AUDIENCE_TYPE, id: experience.id })}>
                        <ListItemText primary="Audience" />
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={() => showEditPanel({ elementSet: ROUTE_PROPERTIES_TYPE, id: experience.id })}>
                        <ListItemText primary="Advanced" />
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={() => showEditPanel({ elementSet: EDITABLE_EXPERIENCE_MAP_TYPE, id: experience.id })}>
                        <ListItemText primary="Map" />
                    </ListItem>
                    {engineEventHandling ? <ListItem button className={classes.nested} onClick={() => showEditPanel({ elementSet: ENGINE_EVENT_HANDLING_TYPE, id: experience.id })}>
                        <ListItemText primary="Game Event Handling" />
                    </ListItem>: null}
                </List>
            </Collapse>
            
            <ObjectsListSelector 
                name="Tracks"
                experienceEditorStore={experienceEditorStore}
                initialOpen={true}
                namedItems={tracks}
                icon={ <BiGitBranch />}
                listName={ROUTE_PART_lIST}/>
            <ObjectsListSelector 
                name="Points"
                experienceEditorStore={experienceEditorStore}
                initialOpen={true}
                namedItems={pointItems}
                icon={ <BiFlag />}
                listName={POINTS_LIST}/>
            <ObjectsListSelector 
                name="Story Points"
                experienceEditorStore={experienceEditorStore}
                initialOpen={true}
                namedItems={contentObjectItems}
                icon={ <BiBookOpen />}
                listName={CONTENT_OBJECTS_LIST}/>
            <ObjectsListSelector 
                name="Instructions"
                experienceEditorStore={experienceEditorStore}
                initialOpen={true}
                namedItems={instructionItems}
                icon={ <BiCommentDots />}
                listName={INSTRUCTION_LIST}
                />
        </List>
        
    );
});

export default ObjectSelector;


// <ListItem button className={classes.nested} onClick={() => showEditPanel({ elementSet: ROUTE_PROPERTIES_TYPE, id: experience.id })}>
//                         <ListItemText primary="Audience" />
//                     </ListItem>
//                     <ListItem button className={classes.nested} onClick={() => showEditPanel({ elementSet: ROUTE_PROPERTIES_TYPE, id: experience.id })}>
//                         <ListItemText primary="Thema's" />
//                     </ListItem>