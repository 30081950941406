import * as React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { inputLabelStyle, formControlStyle } from 'jimbo/ui/Style';



const FormControlWrapper = ({ id, label, showError, children }) => {

    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();



    return <FormControl error={showError} fullWidth classes={formControlClasses}>
        <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
            {label}
        </InputLabel>
        {children}
    </FormControl>
}

export default FormControlWrapper;