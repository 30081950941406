import * as React from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddObjectPanel from "kiini/AddPanel";
import ObjectList from "kiini/ObjectList";


import { 
    ACTIVATE_ASSIGNMENT_TRIGGER,
    NOTIFY_OR_SHOW_ASSIGNMENT,
    SHOW_ROUTE_PART,
    SHOW_SCORE,
} from "./constEventHandlers"

import { 
    createNotifyOrShowAssignmentCommandAction,
    createActivateAssignmentTriggerAction,
    createShowRoutePartAction,
    createCommandAction,
} from "./object-creators"


import ShowGamePointActionWidget from './action-widgets/ShowGamePointActionWidget';
import SelectGameStorySpotWidget from './action-widgets/SelectGameStorySpotWidget';
import SelectRoutePartWidget from './action-widgets/SelectRoutePartWidget';
import NoActionParamsWidget from './action-widgets/NoActionParamsWidget';


const creationOptions = {
    [NOTIFY_OR_SHOW_ASSIGNMENT]: {
        creator: createNotifyOrShowAssignmentCommandAction,
        label: "Show game/storypoint",
        widget: ShowGamePointActionWidget,
        widgetProps: {label: "Story/Game point to show"}
    },
    [ACTIVATE_ASSIGNMENT_TRIGGER]: {
        creator: createActivateAssignmentTriggerAction,
        label: "Activate game/storypoint",
        widget: SelectGameStorySpotWidget,
        widgetProps: {label: "Story/Game point to enable"}
    },
    [SHOW_ROUTE_PART]: {
        creator: createShowRoutePartAction,
        label: "Show track on map",
        widget: SelectRoutePartWidget,
        widgetProps: {label: "Track to show on map"}
    },
    [SHOW_SCORE]: {
        creator: () => createCommandAction({alias: SHOW_SCORE, command: SHOW_SCORE}),
        label: "Show score",
        widget: NoActionParamsWidget,
        widgetProps: {label: "Show the score"}
    }
}

const actionAliasToLabel = {}


const formConfigurationsForActionList = {
    createFormWidget: ({ instance, store, path }) => {
        const alias = instance.alias;
        const Widget = creationOptions[alias].widget;
        const widgetProps = creationOptions[alias].widgetProps;

        return <Widget instance={instance} store={store} path={path} {...widgetProps} />

    },

    getLabel: (instance) => {
        return creationOptions[instance.alias].label;
    }
}



const ActionsWidget = ({ instance, eventName, store }) => {

    //const actions = instance.actions;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const select = (selection) => {
        setAnchorEl(null);
        const newItem = creationOptions[selection].creator();
        instance.addToArray("actions", newItem);
    };

  
    const menuItems = creationOptions ? Object.keys(creationOptions).map(
        (creationOptionClazzName, index) => {
            return (<MenuItem key={index} onClick={() => select(creationOptionClazzName)}>{creationOptions[creationOptionClazzName].label}</MenuItem>)
        }
    ) : null;


    return <>
        <div>Actions for {eventName}</div>
        <ObjectList
            instance={instance}
            store={store}
            fieldName="actions"
            formConfigurations={formConfigurationsForActionList}
            showInsertButtons={false}
            onHandleInsert={null} />
        <AddObjectPanel onAddClick={(event) => handleClick(event)} />
        <Menu id="simple-menu-for-event-actions"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            {menuItems}
        </Menu>
    </>

}



export default ActionsWidget;
