import { createRouterState } from 'mobx-state-router';


const signin = createRouterState('signin');

const checkForUserSignedIn = async (
    fromState,
    toState,
    routerStore
) => {
    const { rootStore } = routerStore.options;
    const { authStore } = rootStore;
    if (!authStore.user) {
        authStore.setSignInRedirect(toState);
        return signin;
    }
};

// Routes are matched from top to bottom. Make sure they are sequenced
// in the order of priority. It is generally best to sort them by pattern,
// prioritizing specific patterns over generic patterns (patterns with
// one or more parameters). For example:
//     /items
//     /items/:id
export const routes = [
    {
        name: 'dashboard',
        pattern: '/',
        beforeEnter: checkForUserSignedIn,
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { homeStore } = rootStore;
            homeStore.loadObjects();
        },
    },
    {
        name: 'experiences',
        pattern: '/experiences',
        beforeEnter: checkForUserSignedIn,
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { experiencesStore } = rootStore;
            experiencesStore.loadObjects();
        },
    },
    {
        name: 'newExperience',
        pattern: '/experiences/new/:type',
        beforeEnter: checkForUserSignedIn,
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { experienceStore } = rootStore;
            experienceStore.newObject({type: toState.params.type});
        },

    },
    {
        name: 'experience',
        pattern: '/experiences/:id',
        beforeEnter: checkForUserSignedIn,
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { experienceStore } = rootStore;
            experienceStore.loadExperience(toState.params.id);
        },
        beforeExit: async(
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { experienceStore } = rootStore;
            const hasUnsavedChanges = experienceStore.hasUnsavedChanges();
            if (hasUnsavedChanges){
                const reallyExit =  window.confirm("The storytrail is changed and not saved, do you really want to leave?");
                if (!reallyExit) {
                    return fromState;
                }
            };
        }
    },
    {
        name: 'storyBlocks',
        pattern: '/storyblocks',
        beforeEnter: checkForUserSignedIn,
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { storyBlocksStore } = rootStore;
            storyBlocksStore.loadObjects();
        },
    },
    {
        name: 'newStoryBlock',
        pattern: '/storyblocks/new',
        beforeEnter: checkForUserSignedIn,
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { storyBlockStore } = rootStore;
            storyBlockStore.newObject({});
        },
        onExit: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { storyBlockStore } = rootStore;
            storyBlockStore.clear();
        }, 
    },
    {
        name: 'storyBlock',
        pattern: '/storyblocks/:id',
        beforeEnter: checkForUserSignedIn,
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { storyBlockStore } = rootStore;
            storyBlockStore.selectObject(toState.params.id);
        },
        beforeExit: async(
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { storyBlockStore } = rootStore;
            const hasUnsavedChanges = storyBlockStore.hasUnsavedChanges();
            if (hasUnsavedChanges){
                const reallyExit =  window.confirm("The storyblock is changed and not saved, do you really want to leave?");
                if (!reallyExit) {
                    return fromState;
                }
            };
        },
        onExit: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { storyBlockStore } = rootStore;
            storyBlockStore.clear();
        }, 
    },
    {
        name: 'fileAssets',
        pattern: '/fileassets',
        beforeEnter: checkForUserSignedIn,
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { fileAssetsStore } = rootStore;
            fileAssetsStore.loadObjects();
        },
    },
    {
        name: 'fileUpload',
        pattern: '/fileassets/upload',
        beforeEnter: checkForUserSignedIn,
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { fileAssetsStore } = rootStore;
            fileAssetsStore.clearSelectObject();
        },
        
    },
    {
        name: 'runnableObjects',
        pattern: '/runnable-objects',
        beforeEnter: checkForUserSignedIn,
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { runnableObjectsStore } = rootStore;
            runnableObjectsStore.loadObjects();
        },
    },
    {
        name: 'newRunnableObject',
        pattern: '/runnable-objects/new',
        beforeEnter: checkForUserSignedIn,
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { runnableObjectsStore } = rootStore;
            runnableObjectsStore.newObject({});
        },
    },
    {
        name: 'notifications',
        pattern: '/notifications',
        beforeEnter: checkForUserSignedIn,
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { notificationsStore } = rootStore;
            notificationsStore.loadObjects();
        },
    },{
        name: 'newNotification',
        pattern: '/notifications/new',
        beforeEnter: checkForUserSignedIn,
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { notificationsStore } = rootStore;
            notificationsStore.newObject({});
        },
        beforeExit: async(
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { notificationsStore } = rootStore;
            const hasUnsavedChanges = notificationsStore.hasUnsavedChanges();
            if (hasUnsavedChanges){
                const reallyExit =  window.confirm("The notification is changed and not saved, do you really want to leave?");
                if (!reallyExit) {
                    return fromState;
                }
            };
        },
        onExit: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { notificationsStore } = rootStore;
            notificationsStore.clear();
        }, 
    },
    {
        name: 'notification',
        pattern: '/notifications/:id',
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { notificationsStore } = rootStore;
            notificationsStore.selectObject(toState.params.id);
        },
        beforeExit: async(
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { notificationsStore } = rootStore;
            const hasUnsavedChanges = notificationsStore.hasUnsavedChanges();
            if (hasUnsavedChanges){
                const reallyExit =  window.confirm("The notificaation is changed and not saved, do you really want to leave?");
                if (!reallyExit) {
                    return fromState;
                }
            };
        },
        onExit: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { notificationsStore } = rootStore;
            notificationsStore.clear();
        }, 
    },{
        name: 'ratings',
        pattern: '/ratings',
        beforeEnter: checkForUserSignedIn,
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { ratingsStore } = rootStore;
            ratingsStore.loadObjects();
        },
    },{
        name: 'rating',
        pattern: '/rating/:id',
        beforeEnter: checkForUserSignedIn,
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { ratingsStore } = rootStore;
            ratingsStore.selectObject(toState.params.id);
        },
    },
    {
        name: 'analytics',
        pattern: '/analytics',
        beforeEnter: checkForUserSignedIn,
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { analyticsStore } = rootStore;
            analyticsStore.loadObjects();
        },
        
    },
    { 
        name: 'notFound', 
        pattern: '/not-found' 
    },
    { 
        name: 'settings', 
        pattern: '/settings',
        beforeEnter: checkForUserSignedIn,
    },
    { 
        name: 'signin', 
        pattern: '/signin',
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { signInStore } = rootStore;
            signInStore.newObject({});
        }, 
    },
    { 
        name: 'forgotPassword', 
        pattern: '/forgot-password',
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { forgotPasswordStore } = rootStore;
            forgotPasswordStore.newObject({});
        },
    },
    {
        name: 'resetPassword',
        pattern: '/resetPassword/:id',
        onEnter: async (
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { resetPasswordStore } = rootStore;
            resetPasswordStore.selectObject(toState.params.id);
        },
        beforeExit: async(
            fromState,
            toState,
            routerStore
        ) => {
            const { rootStore } = routerStore.options;
            const { experienceStore } = rootStore;
            const hasUnsavedChanges = experienceStore.hasUnsavedChanges();
            if (hasUnsavedChanges){
                const reallyExit =  window.confirm("The storytrail is changed and not saved, do you really want to leave?");
                if (!reallyExit) {
                    return fromState;
                }
            };
        }
    },
];
