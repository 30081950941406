import { v4 as uuidv4 } from 'uuid';

import {
    CommandAction
} from 'model/contentObjects';


import {
    NOTIFY_OR_SHOW_ASSIGNMENT,
    ACTIVATE_ASSIGNMENT_TRIGGER,
    SHOW_ROUTE_PART
} from './constEventHandlers';


/* Condition creators */

// export const createIsEnteringActiveAssignmentCondition = () => {
//     return new AliasedCondition(
//         null, {
//         alias: IS_ENTERING_NOT_TRIGGERED_ACTIVE_ASSIGNMENT,
//         id: uuidv4(),
//         values: {}
//     })
// }

// export const createIsEnteringNotTriggerPointCondition = () => {


//     return new AliasedCondition(
//         null, {
//         alias: IS_ENTERING_NOT_TRIGGERED_POINT,
//         id: uuidv4(),
//         values: {}
//     })

// }

// {
//     "_clazz": "content_objects.model.BooleanComparison",
//     type: "for",
//     comparator: IS_FALSE_COMPARATOR,
//     conditionVariable: {
//         "_clazz": "content_objects.model.ConditionVariable",
//         valueType: VARIABLE_TYPE_BOOLEAN,
//         path: "startPoint.notified",
//         id: "@todo",
//         source: VAR_SOURCE_STATE
//     }
// }

// export const createIsAssignmentFinishedCondition = () => {
//     const condition = new AliasedCondition(
//         null, {
//         alias: IS_ASSIGNMENT_FINISHED,
//         id: uuidv4(),
//         values: {}
//     })

//     return condition;
// }

// condition: {
//     "_clazz": "content_objects.model.Comparison",
//     type: "for",
//     comparator: EQUALS_COMPARATOR,
//     left: {
//         "_clazz": "content_objects.model.ConditionVariable",
//         valueType: VARIABLE_TYPE_STRING,
//         path: VAR_CONTENT_OBJECT_LINK_ID,
//         id: "@todo",
//         source: VAR_SOURCE_EVENT
//     },
//     right: {
//         "_clazz": "content_objects.model.StringValue",
//         value: ""
//     }
// }


// event.contentObjectLinkId = {contentObjectLinkId} and
// state.contentObjectsLinks[{contentObjectLinkId}].active == true and
// state.contentObjectsLinks[{contentObjectLinkId}].notified == false
// """
// contentObjectLinkId = fields.CharField(max_length=64)


// export const createEventAction = () => {
//     return new EventAction(
//         null,
//         {
//             id: uuidv4(),
//             actions: [],
//             condition: {
//                 id: uuidv4(),
//                 "_clazz": "content_objects.model.TrueCondition"
//             }
//         })
// }

/* Command Action creators */

export const createCommandAction = ({alias, command}) => {
    return new CommandAction(
        null, {
        id: uuidv4(),
        alias:  alias,
        command: command,
        values: {
        }
    });
}

export const createNotifyOrShowAssignmentCommandAction = () => {
    return new CommandAction(
        null, {
        id: uuidv4(),
        alias:  NOTIFY_OR_SHOW_ASSIGNMENT,
        command: NOTIFY_OR_SHOW_ASSIGNMENT,
        values: {
        }
    });
}



export const createActivateAssignmentTriggerAction = () => {
    return new CommandAction(
        null, {
        id: uuidv4(),
        alias: ACTIVATE_ASSIGNMENT_TRIGGER,
        command: ACTIVATE_ASSIGNMENT_TRIGGER,
        values: {
        }
    })
}

export const createShowRoutePartAction = () => {
    return new CommandAction(
        null, {
        id: uuidv4(),
        alias: SHOW_ROUTE_PART,
        command: SHOW_ROUTE_PART,
        values: {
        }
    })
}