import * as React from 'react';

import FormControlWrapper from 'kiini/FormControlWrapper';


const NoActionParamsWidget = ({ instance, store, path, label }) => {


    return <FormControlWrapper label={label} showError={false}>
        <div>No params</div>
    </FormControlWrapper>

}

export default NoActionParamsWidget;