import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { observer } from "mobx-react";
  
import BaseInput from 'kiini/BaseInput';
import { isUndefined } from 'lodash';


const ArgumentValueSelect = observer(({id, label, instance, fieldName, argumentName, selectOptions}) =>{


    const onChange = (evt) => {
        
        const currentAllValues = !isUndefined(instance[fieldName]) ? instance[fieldName]: {};
        currentAllValues[argumentName] = evt.target.value;
        instance.setValue(fieldName, currentAllValues);

    } 

    
    const menuItems = selectOptions ? selectOptions.map( (entry,index) =>  <MenuItem key={index} value={entry.value}>{entry.label}</MenuItem>): null;
    const value = !isUndefined(instance[fieldName]) && !isUndefined(instance[fieldName][argumentName]) ? instance[fieldName][argumentName]: "";

    return <Select
          id={id}
          value={value}
          onChange={onChange}
          label={label}
          input={<BaseInput />}
        >
          {menuItems}
        </Select>
});

export default ArgumentValueSelect;