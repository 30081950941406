import * as React from "react";
import Box from '@material-ui/core/Box';
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from '@material-ui/core/styles';

import { IoIosAddCircleOutline } from 'react-icons/io';

const useStyles = makeStyles(theme => ({
    addPanel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    }
}));

const AddObjectPanel = ({ onAddClick }) => {

    const classes = useStyles()
    return <Box className={classes.addPanel}>
        <IconButton edge="end" aria-label="add" onClick={onAddClick} >
            <IoIosAddCircleOutline size={16} />
        </IconButton>

    </Box>
}


export default AddObjectPanel;