import * as React from 'react';

import { getConditionWidgetClass } from './engineEventHandlingMeta';

const EventActionConditionWidget = ({ eventAction, store, eventName }) => {
    const ConditionWidget = getConditionWidgetClass(eventAction.condition);

    return <>
        {ConditionWidget ? <ConditionWidget eventName={eventName} instance={eventAction.condition} store={store} /> : null}
    </>
}


export default EventActionConditionWidget;



/*
Special widget instead of instance (condition) we give the evenAction so we can change the condition.

Make it's possible to create conditions based on the type op event.

const EventActionConditionWidget = ( {eventAction, store, eventName}) => {

    
    const ConditionWidget = getConditionWidgetClass(eventAction.condition);

    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const createConditionOptions = getCreateConditionOptions(eventName);

    const select = (selection) => {
        setAnchorEl(null);
        const newItem = createConditionOptions[selection].creator();
        eventAction.setValue("condition", newItem);
    };


    const menuItems = createConditionOptions ? Object.keys(createConditionOptions).map(
        (creationOptionClazzName, index) => {
            return (<MenuItem key={index} onClick={() => select(creationOptionClazzName)}>{createConditionOptions[creationOptionClazzName].label}</MenuItem>)
        }
    ) : null;


    return  <>
        <>{ConditionWidget ? <ConditionWidget eventName={eventName} instance={eventAction.condition} store={store} /> : null}</>
        <AddObjectPanel onAddClick={(event) => handleClick(event)} />
            <Menu id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                {menuItems}
            </Menu>
        </>
    
}

*/


