/* Event names */
export const ON_GAME_OPEN = "onGameOpen"
export const ON_GAME_CLOSE = "onGameClose"

export const ON_ENTER_START_POINT = "onEnterStartPoint"
export const ON_ENTER_END_POINT = "onEnterEndPoint"

export const ON_ENTER_ASSIGNMENT = "onEnterAssignment"
export const ON_FINISH_ASSIGNMENT = "onFinishAssignment"
export const ON_FINISH_ALL_ASSIGNMENTS = "onFinishAllAssignments"




/* Variable types */
// export const VARIABLE_TYPE_BOOLEAN = 'boolean';
// export const VARIABLE_TYPE_INTEGER = 'integer';
// export const VARIABLE_TYPE_STRING = 'string';

/* Variable names */
export const VAR_CONTENT_OBJECT_LINK_ID = 'contentObjectLinkId'
export const VAR_CONTENT_OBJECT_ID = 'contentObjectLinkId'
export const VAR_ROUTE_PART_ID = 'routePartId'
// export const VAR_GAME_ID = 'gameId'
// export const VAR_GAME_STATE = 'gameState'
// export const VAR_GAME_POINT_STATE = 'gamePointState'
// export const VAR_IS_IN_GAME_START_AREA = 'inGameStartArea'

/* Variable sourced */
// export const VAR_SOURCE_STATE = 'state'
// export const VAR_SOURCE_EVENT = 'event'


/* Condition alias names */

export const IS_ENTERING_NOT_TRIGGERED_ACTIVE_ASSIGNMENT = "isEnteringNotTriggeredActiveAssignment"
export const IS_ENTERING_NOT_TRIGGERED_POINT = "isEnteringNotTriggeredPoint"
export const IS_ENTERING_END_POINT = "isEnteringEndPoint"
export const IS_ASSIGNMENT_FINISHED = "isAssignmentFinished"
//export const ARE_ALL_ASSIGNMENTS_FINISHED = "areAllAssignmentFinished"


/* Boolean comparators */
// export const IS_TRUE_COMPARATOR = "true"
// export const IS_FALSE_COMPARATOR = "false"

// export const EQUALS_COMPARATOR = "=="


/* command names */
export const NOTIFY_OR_SHOW_ASSIGNMENT = "notifyOrShowAssignment"
export const ACTIVATE_ASSIGNMENT_TRIGGER = "activateAssignmentTrigger"
export const SHOW_ROUTE_PART = "showRoutePart"
export const SHOW_SCORE = "showScore"


