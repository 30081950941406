
import TrueConditionWidget from './condition-widgets/TrueConditionWidget'
import FirstNotificationStartPointWidget from './condition-widgets/FirstNotificationStartPointWidget'
import FirstNotificationEndPointWidget from './condition-widgets/FirstNotificationEndPointWidget'
import GamePointConditionWidget from './condition-widgets/GamePointConditionWidget'

import { isUndefined } from 'lodash';
import { IS_ASSIGNMENT_FINISHED, 
    IS_ENTERING_NOT_TRIGGERED_ACTIVE_ASSIGNMENT, 
    IS_ENTERING_END_POINT,
    IS_ENTERING_NOT_TRIGGERED_POINT } from './constEventHandlers';


export const CONDITION_WIDGETS_CLASSES = {
    "content_objects.model.TrueCondition": TrueConditionWidget
}

export const ALIASED_CONDITION_WIDGETS_CLASSES = {
    [IS_ENTERING_NOT_TRIGGERED_POINT]: FirstNotificationStartPointWidget,
    [IS_ENTERING_END_POINT]: FirstNotificationEndPointWidget,
    [IS_ASSIGNMENT_FINISHED]: GamePointConditionWidget,
    [IS_ENTERING_NOT_TRIGGERED_ACTIVE_ASSIGNMENT]: GamePointConditionWidget,
    [IS_ENTERING_NOT_TRIGGERED_POINT]: FirstNotificationStartPointWidget,
}


export const getConditionWidgetClass = (condition) => {

    const clazzName = condition.$classDescription.clazzName

    if (clazzName === "content_objects.model.AliasedCondition"){
        const alias = condition.alias;
        const widget = ALIASED_CONDITION_WIDGETS_CLASSES[alias];
        if (isUndefined(widget)){
            console.error("No widget defined for condition alias ", alias)
            return null;
        }else{
            return widget;
        }
    }

    return CONDITION_WIDGETS_CLASSES[clazzName];
}



// import { 
//     ON_GAME_OPEN, 
//     ON_GAME_CLOSE, 
//     ON_ENTER_START_POINT, 
//     ON_ENTER_END_POINT, 
//     ON_FINISH_ASSIGNMENT, 
//     ON_ENTER_ASSIGNMENT 
// } from "./constEventHandlers";


// export const variable_definitions = {
//     [VAR_GAME_STATE]: {
//         name: VAR_GAME_ID,
//         varType: VARIABLE_TYPE_STRING,
//         options: ['new', 'running', 'done'],
//     },
//     [VAR_GAME_ID]: {
//         name: VAR_GAME_ID,
//         varType: VARIABLE_TYPE_BOOLEAN,
//     },
//     [VAR_GAME_POINT_ID]: {
//         name: VAR_GAME_POINT_ID,
//         varType: VARIABLE_TYPE_STRING,
//     },
//     [VAR_GAME_STATE]: {
//         name: VAR_GAME_STATE,
//         varType: VARIABLE_TYPE_STRING,
//         options: ['new', 'running', 'done']
//     },
//     [VAR_IS_IN_GAME_START_AREA]: {
//         name: VAR_GAME_STATE,
//         varType: VARIABLE_TYPE_BOOLEAN,
//     }

// }



// export const event_variables = {
//     [ON_GAME_OPEN]: [VAR_GAME_STATE, VAR_GAME_ID, VAR_IS_IN_GAME_START_AREA],
//     [ON_GAME_CLOSE]: [VAR_GAME_STATE, VAR_GAME_ID],
//     [ON_ENTER_START_POINT]: [VAR_GAME_STATE, VAR_GAME_ID],
//     [ON_ENTER_GAME_POINT]: [VAR_GAME_POINT_ID, VAR_GAME_STATE, VAR_GAME_ID],
// }


// export const event_conditions = {
//     [ON_GAME_OPEN]: ["content_objects.model.GameStateIsCondition"]
// }
/*
These option detemine which conditions can be used as filter for specific events.
*/

// const creationOptions = {
//     [ON_ENTER_GAME_POINT]: {
//         'ShouldNotifyForContentObjectLink': {
//             creator: createIsContentObjectLinkEnabledCondition,
//             label: "Notify Object if active"
//         },
//     },
//     [ON_ENTER_START_POINT]: {
//         [START_POINT_FIRST_NOTIFICATION_COND_ALIAS]: {
//             creator: createStartPointFirstNotificationCondition,
//             label: "Only the first notification"
//         }
//     },
//     [ON_FINISH_GAME_POINT]: {
//         [FINISH_GAME_POINT_ALIAS]: {
//             creator: createFinishGamePointCondition,
//             label: "Storypoint"
//         }
//     }
// }

/*
Returns condition options for given eventName
*/
// export const getCreateConditionOptions = (eventName) => {
//     const createOptionsForEventName = creationOptions[eventName];
//     if (isUndefined(createOptionsForEventName)){
//         console.error("No create options for event ", eventName);
//         return {}
//     }
//     return createOptionsForEventName;
// }