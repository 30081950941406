import * as React from 'react';

import { isUndefined } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { FormConfigurations } from 'kiini/Forms';
import ObjectListWithPanel from 'kiini/ObjectListWithPanel';
import {InstancePropertyFormWidget} from 'kiini/Forms';

import {getRoleWithClazz} from 'model/content-object-utils';


import { 
    EngineEventHandler,
} from 'model/contentObjects';



import {
    ON_GAME_OPEN, 
    ON_GAME_CLOSE, 
    ON_ENTER_START_POINT, 
    ON_ENTER_END_POINT, 
    ON_ENTER_ASSIGNMENT,
    ON_FINISH_ASSIGNMENT,
    ON_FINISH_ALL_ASSIGNMENTS,
    IS_ASSIGNMENT_FINISHED,
    IS_ENTERING_NOT_TRIGGERED_ACTIVE_ASSIGNMENT,
    IS_ENTERING_NOT_TRIGGERED_POINT,
    IS_ENTERING_END_POINT
} from './constEventHandlers'

import EventActionConditionWidget from './EventActionConditionWidget';
import ActionsWidget from './ActionsWidget';



const createEngineEventHandlerWidget = ({ instance, store, formConfigurations, label, path, widgetProps }) => {

    //console.log("createEngineEventHandlerWidget", store)
    const engineEventHandler = instance;
    const eventName = engineEventHandler.eventName;
    
    /*
    Creates a new eventAction, default with the TrueCondition
    */
     return <div>
        <div>{eventName}</div>
        <EventActionConditionWidget eventAction={engineEventHandler.eventAction} store={store} formConfigurations={formConfigurations} eventName={eventName} />
        <ActionsWidget instance={engineEventHandler.eventAction} store={store} formConfigurations={formConfigurations} eventName={eventName} />
    </div>
}


export const eventHandlerFormConfigurationData = {
    'content_objects.model.EngineEventHandler': {
        createWidget: createEngineEventHandlerWidget
    }
}


const eventHandlerFormConfigurations = new FormConfigurations(eventHandlerFormConfigurationData);


const EVENT_TO_CONDITION_ALIAS = {
    [ON_ENTER_START_POINT]: IS_ENTERING_NOT_TRIGGERED_POINT,
    [ON_ENTER_ASSIGNMENT]: IS_ENTERING_NOT_TRIGGERED_ACTIVE_ASSIGNMENT,
    [ON_FINISH_ASSIGNMENT]: IS_ASSIGNMENT_FINISHED,
    [ON_ENTER_START_POINT]: IS_ENTERING_END_POINT
}

function createCondition({eventName}){
    
    const alias = EVENT_TO_CONDITION_ALIAS[eventName];


    if ( isUndefined(alias)){
    
        return {
            id: uuidv4(),
            _clazz: "content_objects.model.TrueCondition"
            }
        
    }else{
        const alias = EVENT_TO_CONDITION_ALIAS[eventName];

        const aliasedCondition = {
            id: uuidv4(),
            _clazz: "content_objects.model.AliasedCondition",
            alias,
            values: {}
        }
        return aliasedCondition 
    }
}

function createEngineEventHandler({eventName}){

    const condition = createCondition({eventName})


    return new EngineEventHandler(
        null, {
            id: uuidv4(), 
            eventName:eventName, 
            eventAction:{
                id: uuidv4(),
                condition
            }
        });
}

const eventHandlerCreationOptions = {
    'onGameOpen': {
        label: "On game open", 
        creator: () => createEngineEventHandler({eventName:ON_GAME_OPEN})
    },
    'onGameClose': {
        label: "On game close", 
        creator: () => createEngineEventHandler({eventName:ON_GAME_CLOSE})
    },
    [ON_ENTER_START_POINT]: {
        label: "On enter start area", 
        creator: () => createEngineEventHandler({eventName:ON_ENTER_START_POINT})
    },
    [ON_ENTER_END_POINT]: {
        label: "On enter end area", 
        creator: () => createEngineEventHandler({eventName:ON_ENTER_END_POINT})
    },
    [ON_ENTER_ASSIGNMENT]: {
        label: "On enter assignment area", 
        creator: () => createEngineEventHandler({eventName:ON_ENTER_ASSIGNMENT})
    },
    [ON_FINISH_ASSIGNMENT]: {
        label: "On finish assignment", 
        creator: () => createEngineEventHandler({eventName:ON_FINISH_ASSIGNMENT})
    },
    [ON_FINISH_ALL_ASSIGNMENTS]: {
        label: "On finish all assignments", 
        creator: () => createEngineEventHandler({eventName:ON_FINISH_ALL_ASSIGNMENTS})
    },
}


const EngineEventHandlerForm = ({instance, store}) => {

    const engineEventHandling = getRoleWithClazz(instance.contentRoles, "content_objects.roles.EngineEventHandling");
    return <InstancePropertyFormWidget 
        instance={engineEventHandling} 
        store={store}
        fieldName="eventHandlers" 
        label="Events handlers"
        Widget={ObjectListWithPanel}
        widgetProps={{
            creationOptions: eventHandlerCreationOptions, 
            oneInstancePerClassInList: false,
        }}
        formConfigurations={eventHandlerFormConfigurations}/>
    //return <div>Create here the event handles</div>
}


export default EngineEventHandlerForm;
